import {
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  ecommerceOutlookAnimation,
  shoppingBagAnimation,
} from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  selectLoggedInUser,
  signupAsync,
  selectSignupStatus,
  selectSignupError,
  clearSignupError,
  resetSignupStatus,
} from "../AuthSlice";
import { toast } from "react-toastify";
import { MotionConfig, motion } from "framer-motion";

export const Signup = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectSignupStatus);
  const error = useSelector(selectSignupError);
  const loggedInUser = useSelector(selectLoggedInUser);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const theme = useTheme();
  const is900 = useMediaQuery(theme.breakpoints.down(900));
  const is480 = useMediaQuery(theme.breakpoints.down(480));

  // handles user redirection
  useEffect(() => {
    if (loggedInUser && !loggedInUser?.isVerified) {
      navigate("/verify-otp");
    } else if (loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser]);

  // handles signup error and toast them
  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (status === "fullfilled") {
      toast.success(
        "Welcome! Verify your email to start shopping on mern-ecommerce."
      );
      reset();
    }
    return () => {
      dispatch(clearSignupError());
      dispatch(resetSignupStatus());
    };
  }, [status]);

  // this function handles signup and dispatches the signup action with credentails that api requires
  const handleSignup = (data) => {
    const cred = { ...data };
    delete cred.confirmPassword;
    dispatch(signupAsync(cred));
  };

  return (
    <Stack
      width={"100vw"}
      height={"100vh"}
      flexDirection={"row"}
      sx={{ overflowY: "hidden" }}
    >
      {!is900 && (
        <Stack bgcolor={"black"} flex={1} justifyContent={"center"}>
          {/* <Lottie animationData={ecommerceOutlookAnimation}/> */}
          <motion.img
          animate={{
            delay:20,
            duration:800,
            opacity:[0,0.2,0.4,0.6,0.8,1]}}
            src="/logo.png"
            alt=""
            className="w-10"
            style={{ width: "100%" }}
          />
        </Stack>
      )}

      <Stack
        flex={1}
        bgcolor={"black"}
        justifyContent={"center"}
        alignItems={"center"}
      >
         {is900&&
        <>
          <motion.img animate={{opacity:[0,0.2,0.4,0.6,0.8,1]}} src="/feather.png" width={"20%"}></motion.img>
      
        <br />
        </>
        }
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack rowGap={".4rem"}>
            <motion.div
              animate={{
                y: [100, 0],
                opacity: [0,0.5, 1],
                transition: { ease: ["easeIn", "easeOut"] },
                duration:500
              }}
              >
              <Typography
                color={"white"}
                borderColor={"black"}
                
                variant="h2"
                sx={{ wordBreak: "break-word" }}
                fontWeight={600}
              >
                Skin Lays{" "}
              </Typography>
            </motion.div>
            <motion.Typography
            animate={{
              y: [-100, 0],
              opacity: [0,0.5, 1],
              transition: { ease: ["easeIn", "easeOut"] },
              duration:800
           
            }}
              alignSelf={"flex-end"}
              color={"GrayText"}
              variant="body2"
            >
              - Feel Feathers
            </motion.Typography>
          </Stack>
        </Stack>

        <Stack
          mt={4}
          spacing={2}
          width={is480 ? "95vw" : "28rem"}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(handleSignup)}
        >
          <MotionConfig whileHover={{ y: -5 }}>
            <motion.div>
              <TextField
                style={{ backgroundColor: "white" }}
                fullWidth
                {...register("name", { required: "Username is required" })}
                placeholder="Username"
              />
              {errors.name && (
                <FormHelperText error>{errors.name.message}</FormHelperText>
              )}
            </motion.div>


            <motion.div>
              <TextField

style={{ backgroundColor: "white" }}
                fullWidth
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                    message: "Enter a valid email",
                  },
                })}
                placeholder="Email"
              />
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </motion.div>
            <motion.div>
              <TextField

style={{ backgroundColor: "white" }}
                fullWidth
                {...register("mobile", {
                  required: "mobile is required",
                  pattern: {
                    value:/^\d{10}$/,
                    message: "Enter a valid mobile",
                  },
                })}
                placeholder="mobile"
              />
              {errors.mobile && (
                <FormHelperText error>{errors.mobile.message}</FormHelperText>
              )}
            </motion.div>


            <motion.div>
              <TextField

style={{ backgroundColor: "white" }}
                fullWidth
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                    message: `at least 8 characters, must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number, Can contain special characters`,
                  },
                })}
                placeholder="Password"
              />
              {errors.password && (
                <FormHelperText error>{errors.password.message}</FormHelperText>
              )}
            </motion.div>

            <motion.div>
              <TextField

style={{ backgroundColor: "white" }}
                fullWidth
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value, fromValues) =>
                    value === fromValues.password || "Passwords doesn't match",
                })}
                placeholder="Confirm Password"
              />
              {errors.confirmPassword && (
                <FormHelperText error>
                  {errors.confirmPassword.message}
                </FormHelperText>
              )}
            </motion.div>
          </MotionConfig>

          <motion.div animate={{
                opacity:[0,0.2,0.4,0.6,0.8,1],
                // y:[100,80,60,40,20,0],
                transition: { ease: ["easeIn", "easeOut"] },
                
                duration:900,
                
                delay:'400'
              }} whileHover={{ scale: 1.02 }} whileTap={{ scale: 1 }}>
            <LoadingButton
            style={{background:'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)'}}
              sx={{ height: "2.5rem" }}
              fullWidth
              loading={status === "pending"}
              type="submit"
              variant="contained"
              
            >
              Signup
            </LoadingButton>
          </motion.div>

          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap-reverse"}
          >
            <MotionConfig whileHover={{ x: 2 }} whileTap={{ scale: 1.05 }}>
              <motion.div>
                <Typography
                  mr={"1.5rem"}
                  sx={{ textDecoration: "none", color: "gold" }}
                  to={"/forgot-password"}
                  component={Link}
                >
                  Forgot password
                </Typography>
              </motion.div>

              <motion.div>
                <Typography
                  sx={{ textDecoration: "none", color: "white" }}
                  to={"/login"}
                  component={Link}
                >
                  Already a member?{" "}
                  <span style={{ color: "goldenrod" }}>
                    Login
                  </span>
                </Typography>
              </motion.div>
            </MotionConfig>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
