import React from 'react'
import Atropos from 'atropos/react';
import 'atropos/css'
import { hoodieParallax } from '../../assets';
import { Box } from '@mui/material';

export default function Hover3d() {
  return (
    <Box sx={{padding:1}}>
         <Atropos 
        activeOffset={40}
        shadowScale={1.05}
        onEnter={() => console.log('Enter')}
        onLeave={() => console.log('Leave')}
        onRotate={(x, y) => console.log('Rotate', x, y)}
      >
        <img src="/Bannerbig.jpeg" height={"100%"} width={"100%"} alt="" />
        {/* ... */}
      </Atropos>
    </Box>
  )
}
