import React from 'react'
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import Hover3d from './Hover3d';

export default function Parallax() {
  return (
    <div >
    <ScrollParallax>
<Hover3d/>
    </ScrollParallax>
    </div>
  )
}
