import {
  Box,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  ecommerceOutlookAnimation,
  shoppingBagAnimation,
} from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton, yearPickerClasses } from "@mui/lab";
import {
  selectLoggedInUser,
  loginAsync,
  selectLoginStatus,
  selectLoginError,
  clearLoginError,
  resetLoginStatus,
} from "../AuthSlice";
import { toast } from "react-toastify";
import { MotionConfig, motion } from "framer-motion";

export const Login = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectLoginStatus);
  const error = useSelector(selectLoginError);
  const loggedInUser = useSelector(selectLoggedInUser);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const theme = useTheme();
  const is900 = useMediaQuery(theme.breakpoints.down(900));
  const is480 = useMediaQuery(theme.breakpoints.down(480));

  // handles user redirection
  useEffect(() => {
    if (loggedInUser && loggedInUser?.isVerified) {
      navigate("/");
    } else if (loggedInUser && !loggedInUser?.isVerified) {
      navigate("/verify-otp");
    }
  }, [loggedInUser]);

  // handles login error and toast them
  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  // handles login status and dispatches reset actions to relevant states in cleanup
  useEffect(() => {
    if (status === "fullfilled" && loggedInUser?.isVerified === true) {
      toast.success(`Login successful`);
      reset();
    }
    return () => {
      dispatch(clearLoginError());
      dispatch(resetLoginStatus());
    };
  }, [status]);

  const handleLogin = (data) => {
    const cred = { ...data };
    delete cred.confirmPassword;
    dispatch(loginAsync(cred));
  };

  return (
    <Stack
      width={"100vw"}
      height={"100vh"}
      bgcolor={"black"}
      flexDirection={"row"}
      sx={{ overflowY: "hidden" }}
    >
      {!is900 && (
        <Stack bgcolor={"black"} flex={1} justifyContent={"center"}>
          {/* <Lottie animationData={ecommerceOutlookAnimation}/> */}
          <motion.img

animate={{
  delay:20,
  duration:800,
  opacity:[0,0.2,0.4,0.6,0.8,1]}}
            src="/logo.png"
            alt=""
            className="w-10"
            style={{ width: "100%" }}
          />
        </Stack>
      )}

      <Stack
        color={"white"}
        style={{ backgroundColor: "" }}
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        >
        {is900&&
        <>
        <motion.img animate={{opacity:[0,0.2,0.4,0.6,0.8,1]}} src="/feather.png" width={"20%"}></motion.img>
        <br />
        </>
        }
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          >
          
          <Stack rowGap={".4rem"}>
            <motion.div
              animate={{
                y: [100, 0],
                opacity: [0,0.5, 1],
                transition: { ease: ["easeIn", "easeOut"] },
                duration:500
              }}
              >
              <Typography
                color={"white"}
                borderColor={"black"}
                
                variant="h2"
                sx={{ wordBreak: "break-word" }}
                fontWeight={600}
              >
                Skin Lays{" "}
              </Typography>
            </motion.div>
            <motion.Typography
            animate={{
              y: [-100, 0],
              opacity: [0,0.5, 1],
              transition: { ease: ["easeIn", "easeOut"] },
              duration:800
           
            }}
            color={'white'}
              alignSelf={"flex-end"}
              // variant="body2"
            >
              <span style={{color:'white'}}>

              Feel Feathers
              </span>
            </motion.Typography>
          </Stack>
        </Stack>

        <Stack
          mt={4}
          spacing={2}
          width={is480 ? "95vw" : "28rem"}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(handleLogin)}
        >
          <motion.div style={{backgroundColor:"white"}} whileHover={{ y: -5 }}>
            <TextField
              fullWidth
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                  message: "Enter a valid email",
                },
              })}
              placeholder="Email"
            />
            {errors.email && (
              <FormHelperText sx={{ mt: 1 }} error>
                {errors.email.message}
              </FormHelperText>
            )}
          </motion.div>

          <motion.div style={{backgroundColor:"white"}}  whileHover={{ y: -5 }}>
            <TextField
              type="password"
              fullWidth
              {...register("password", { required: "Password is required" })}
              placeholder="Password"
            />
            {errors.password && (
              <FormHelperText sx={{ mt: 1 }} error>
                {errors.password.message}
              </FormHelperText>
            )}
          </motion.div>

          <motion.div animate={{
                opacity:[0,0.2,0.4,0.6,0.8,1],
                // y:[100,80,60,40,20,0],
                transition: { ease: ["easeIn", "easeOut"] },
                
                duration:900,
                
                delay:'400'
              }} style={{backgroundColor:'yellow'}} whileHover={{ scale: 1.02 }} whileTap={{ scale: 1 }}>
            <LoadingButton
            style={{background:'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)'}}
              fullWidth
              sx={{ height: "2.5rem" }}
              loading={status === "pending"}
              type="submit"
              variant="contained"
            >
              Login
            </LoadingButton>
          </motion.div>

          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap-reverse"}
          >
            <MotionConfig whileHover={{ x: 2 }} whileTap={{ scale: 1.05 }}>
              <motion.div>
                <Typography
                  mr={"1.5rem"}
                  sx={{ textDecoration: "none", color: "white" }}
                  to={"/forgot-password"}
                  component={Link}
                >
                  Forgot password
                </Typography>
              </motion.div>

              <motion.div>
                <Typography
                  sx={{ textDecoration: "none", color: "white" }}
                  to={"/signup"}
                  component={Link}
                >
                  Don't have an account?{" "}
                  <span style={{ color: 'gold' }}>
                    Register
                  </span>
                </Typography>
              </motion.div>
            </MotionConfig>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
