import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addProductAsync,
  resetProductAddStatus,
  selectProductAddStatus,
  updateProductByIdAsync,
} from "../../products/ProductSlice";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { selectBrands } from "../../brands/BrandSlice";
import { selectCategories } from "../../categories/CategoriesSlice";
import { toast } from "react-toastify";

export const AddProduct = () => {
  const [imgs, setImages] = useState(["", "", "", ""]);
  const [garment, setGarment] = useState("tshirt");
  useEffect(() => {
    console.log({ imgs });
  }, [imgs]);
  console.log({ imgs });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    thumbnails: ["", "", ""],
  });

  const dispatch = useDispatch();
  const brands = useSelector(selectBrands);
  const categories = useSelector(selectCategories);
  const productAddStatus = useSelector(selectProductAddStatus);
  const navigate = useNavigate();
  const theme = useTheme();
  const is1100 = useMediaQuery(theme.breakpoints.down(1100));
  const is480 = useMediaQuery(theme.breakpoints.down(480));

  useEffect(() => {
    if (productAddStatus === "fullfilled") {
      reset();
      toast.success("New product added");
      navigate("/admin/dashboard");
    } else if (productAddStatus === "rejected") {
      toast.error("Error adding product, please try again later");
    }
  }, [productAddStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetProductAddStatus());
    };
  }, []);

  const handleAddProduct = (data) => {
    console.log({data})
    const newProduct = {
      ...data,
      // images: imgs,
      // images:[data.image0,data.image1,data.image2,data.image3]
    };
    // delete newProduct.image0;
    // delete newProduct.image1;
    // delete newProduct.image2;
    // delete newProduct.image3;

    dispatch(addProductAsync(newProduct));
  };

  return (
    <Stack
      p={"0 16px"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"row"}
    >
      <Stack
        width={is1100 ? "100%" : "60rem"}
        rowGap={4}
        mt={is480 ? 4 : 6}
        mb={6}
        component={"form"}
        noValidate
        onSubmit={handleSubmit(handleAddProduct)}
      >
        {/* feild area */}
        <Stack rowGap={3}>
          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Title
            </Typography>
            <TextField
              {...register("title", { required: "Title is required" })}
            />
          </Stack>

          <Stack flexDirection={"row"}>
            <FormControl fullWidth>
              <InputLabel id="brand-selection">Brand</InputLabel>
              <Select
                {...register("brand", { required: "Brand is required" })}
                labelId="brand-selection"
                label="Brand"
              >
                {brands.map((brand) => (
                  <MenuItem value={brand._id}>{brand.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="category-selection">Category</InputLabel>
              <Select
                {...register("category", { required: "category is required" })}
                labelId="category-selection"
                label="Category"
              >
                {categories.map((category) => (
                  <MenuItem value={category._id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Description
            </Typography>
            <TextField
              multiline
              rows={4}
              {...register("description", {
                required: "Description is required",
              })}
            />
          </Stack>

          <Stack flexDirection={"row"}>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Price
              </Typography>
              <TextField
                type="number"
                {...register("price", { required: "Price is required" })}
              />
            </Stack>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Discount {is480 ? "%" : "Percentage"}
              </Typography>
              <TextField
                type="number"
                {...register("discountPercentage", {
                  required: "discount percentage is required",
                })}
              />
            </Stack>
          </Stack>
          {/* Hoodie Price */}
          <Typography variant="h5">Hoodie</Typography>
          <Stack flexDirection={"row"}>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Price
              </Typography>
              <TextField
                type="number"
                {...register("hoodiePrice.price", {
                  required: "Price is required",
                })}
              />
            </Stack>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Discount {is480 ? "%" : "Percentage"}
              </Typography>
              <TextField
                type="number"
                {...register("hoodiePrice.discountPercentage", {
                  required: "discount percentage is required",
                })}
              />
            </Stack>
          </Stack>

          {/* Sweatshirt Price */}
          <Typography variant="h5">Sweatshirt</Typography>
          <Stack flexDirection={"row"}>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Price
              </Typography>
              <TextField
                type="number"
                {...register("sweatShirtPrice.price", {
                  required: "Price is required",
                })}
              />
            </Stack>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Discount {is480 ? "%" : "Percentage"}
              </Typography>
              <TextField
                type="number"
                {...register("sweatShirtPrice.discountPercentage", {
                  required: "discount percentage is required",
                })}
              />
            </Stack>
          </Stack>

          {/* Tshirt Price */}
          <Typography variant="h5">Tshirt</Typography>
          <Stack flexDirection={"row"}>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Price
              </Typography>
              <TextField
                type="number"
                {...register("tshirtPrice.price", {
                  required: "Price is required",
                })}
              />
            </Stack>
            <Stack flex={1}>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Discount {is480 ? "%" : "Percentage"}
              </Typography>
              <TextField
                type="number"
                {...register("tshirtPrice.discountPercentage", {
                  required: "discount percentage is required",
                })}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Stock Quantity
            </Typography>
            <TextField
              type="number"
              {...register("stockQuantity", {
                required: "Stock Quantity is required",
              })}
            />
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Thumbnail
            </Typography>

            <TextField
              {...register(`thumbnail`, { required: "Thumbnail is required" })}
            />
          </Stack>

          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Thumbnail
            </Typography>
            {[...Array(3)].map((i, index) => (
              <TextField
                {...register(`thumbnails.[${index}]`, {
                  required: "Thumbnail is required",
                })}
              />
            ))}
          </Stack>

          <Stack>
            <Typography variant="h6" fontWeight={400} gutterBottom>
              Product Images
            </Typography>
            <Stack rowGap={2}>
              {/* <Button
                variant="contained"
                onClick={() => setImages([...imgs, ""])}
              >
                Add Image
              </Button>
              <Typography>Garment={garment}</Typography>
              <Select onChange={(e) => setGarment(e.target.value)}>
                <MenuItem value={"tshirt"}> tshirt</MenuItem>
                <MenuItem value={"hoodie"}>Hoodie</MenuItem>
                <MenuItem value={"sweatshirt"}>Sweatshirt</MenuItem>
              </Select> */}
              <h4>Hoodie pics</h4>
              {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.hoodie[${index}]`, {
                      required: "Image is required",
                      // value: selectedProduct?.imgs?.hoodie?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
              <h4>Tshirt Images</h4>
               {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.tshirt[${index}]`, {
                      required: "Image is required",
                      // value: selectedProduct?.imgs?.tshirt?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
              <h4>Sweatshirt Images</h4>
               {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.sweatshirt[${index}]`, {
                      required: "Image is required",
                      // value: selectedProduct?.imgs?.sweatshirt?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
              {/* {imgs?.map((i, index) => (
                <>
                  <TextField
                    label={index}
                    {...register(`imgs[${index}]`,{required:"Image is required"})}
                    onChange={(event) => {
                      // console.log(event?.target?.value)
                      imgs[index] = event?.target?.value;
                      console.log(imgs);
                      setImages(imgs);
                    }}
                  />
                </>
              ))} */}
              {/* {imgs?.map((i, index) => (
                <>
                  <TextField
                    label={index}
                    {...register(`imgs.${garment}[${index}]`, {
                      required: "Image is required",
                    })}
                    // onChange={(event) => {
                    //   imgs[index] = event?.target?.value;
                    //   console.log(imgs);
                    //   setImages(imgs);
                    // }}
                  />
                </>
              ))} */}
            </Stack>
          </Stack>
        </Stack>

        {/* action area */}
        <Stack
          flexDirection={"row"}
          alignSelf={"flex-end"}
          columnGap={is480 ? 1 : 2}
        >
          <Button
            size={is480 ? "medium" : "large"}
            variant="contained"
            type="submit"
          >
            Add Product
          </Button>
          <Button
            size={is480 ? "medium" : "large"}
            variant="outlined"
            color="error"
            component={Link}
            to={"/admin/dashboard"}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
