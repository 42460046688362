import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clearSelectedProduct,
  fetchProductByIdAsync,
  resetProductUpdateStatus,
  selectProductUpdateStatus,
  selectSelectedProduct,
  updateProductByIdAsync,
} from "../../products/ProductSlice";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { selectBrands } from "../../brands/BrandSlice";
import { selectCategories } from "../../categories/CategoriesSlice";
import { toast } from "react-toastify";

export const ProductUpdate = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm();
  const [garment, setGarment] = useState("tshirt");

  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(selectSelectedProduct);
  // console.log({selectedProduct})
  const brands = useSelector(selectBrands);
  const categories = useSelector(selectCategories);
  const productUpdateStatus = useSelector(selectProductUpdateStatus);
  const navigate = useNavigate();
  const theme = useTheme();
  const is1100 = useMediaQuery(theme.breakpoints.down(1100));
  const is480 = useMediaQuery(theme.breakpoints.down(480));
  useEffect(() => {
    console.log({ register });
  }, [register]);

  const [imgs, setImages] = useState([]);
  useEffect(() => {
    setImages(selectedProduct?.images);
  }, [id, selectedProduct]);
  useEffect(() => {
    if (id) {
      dispatch(fetchProductByIdAsync(id));
    }
  }, [id]);

  useEffect(() => {
    if (productUpdateStatus === "fullfilled") {
      toast.success("Product Updated");
      navigate("/admin/dashboard");
    } else if (productUpdateStatus === "rejected") {
      toast.error("Error updating product, please try again later");
    }
  }, [productUpdateStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedProduct());
      dispatch(resetProductUpdateStatus());
    };
  }, []);

  const handleProductUpdate = (data) => {
    console.log({ data });
    const productUpdate = {
      ...data,
      _id: selectedProduct._id,
      images: data?.images,
    };
    delete productUpdate?.image0;
    delete productUpdate?.image1;
    delete productUpdate?.image2;
    delete productUpdate?.image3;

    dispatch(updateProductByIdAsync(productUpdate));
  };

  return (
    <Stack
      p={"0 16px"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"row"}
    >
      {selectedProduct && (
        <Stack
          width={is1100 ? "100%" : "60rem"}
          rowGap={4}
          mt={is480 ? 4 : 6}
          mb={6}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(handleProductUpdate)}
        >
          {/* feild area */}
          <Stack rowGap={3}>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Title
              </Typography>
              <TextField
                {...register("title", {
                  required: "Title is required",
                  value: selectedProduct.title,
                })}
              />
            </Stack>

            <Stack flexDirection={"row"}>
              <FormControl fullWidth>
                <InputLabel id="brand-selection">Brand</InputLabel>
                <Select
                  defaultValue={selectedProduct.brand._id}
                  {...register("brand", { required: "Brand is required" })}
                  labelId="brand-selection"
                  label="Brand"
                >
                  {brands.map((brand) => (
                    <MenuItem value={brand._id}>{brand.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="category-selection">Category</InputLabel>
                <Select
                  defaultValue={selectedProduct.category._id}
                  {...register("category", {
                    required: "category is required",
                  })}
                  labelId="category-selection"
                  label="Category"
                >
                  {categories.map((category) => (
                    <MenuItem value={category._id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Description
              </Typography>
              <TextField
                multiline
                rows={4}
                {...register("description", {
                  required: "Description is required",
                  value: selectedProduct.description,
                })}
              />
            </Stack>

            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Price
                </Typography>
                <TextField
                  type="number"
                  {...register("price", {
                    required: "Price is required",
                    value: selectedProduct.price,
                  })}
                />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Discount {is480 ? "%" : "Percentage"}
                </Typography>
                <TextField
                  type="number"
                  {...register("discountPercentage", {
                    required: "discount percentage is required",
                    value: selectedProduct.discountPercentage,
                  })}
                />
              </Stack>
            </Stack>
            {/* Hoodie Price */}
            <Typography variant="h5">Hoodie</Typography>
            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Price
                </Typography>
                <TextField
                  type="number"
                  {...register("hoodiePrice.price", {
                    required: "Price is required",
                    value: selectedProduct?.hoodiePrice?.price,
                  })}
                />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Discount {is480 ? "%" : "Percentage"}
                </Typography>
                <TextField
                  type="number"
                  {...register("hoodiePrice.discountPercentage", {
                    required: "discount percentage is required",
                    value: selectedProduct?.hoodiePrice?.discountPercentage,
                  })}
                />
              </Stack>
            </Stack>

            {/* Sweatshirt Price */}
            <Typography variant="h5">Sweatshirt</Typography>
            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Price
                </Typography>
                <TextField
                  type="number"
                  {...register("sweatShirtPrice.price", {
                    required: "Price is required",
                    value: selectedProduct?.sweatShirtPrice?.price,
                  })}
                />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Discount {is480 ? "%" : "Percentage"}
                </Typography>
                <TextField
                  type="number"
                  {...register("sweatShirtPrice.discountPercentage", {
                    required: "discount percentage is required",
                    value: selectedProduct?.sweatShirtPrice?.discountPercentage,
                  })}
                />
              </Stack>
            </Stack>

            {/* Tshirt Price */}
            <Typography variant="h5">Tshirt</Typography>
            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Price
                </Typography>
                <TextField
                  type="number"
                  {...register("tshirtPrice.price", {
                    required: "Price is required",
                    value: selectedProduct?.tshirtPrice?.price,
                  })}
                />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Discount {is480 ? "%" : "Percentage"}
                </Typography>
                <TextField
                  type="number"
                  {...register("tshirtPrice.discountPercentage", {
                    required: "discount percentage is required",
                    value: selectedProduct?.tshirtPrice?.discountPercentage,
                  })}
                />
              </Stack>
            </Stack>

            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Stock Quantity
              </Typography>
              <TextField
                type="number"
                {...register("stockQuantity", {
                  required: "Stock Quantity is required",
                  value: selectedProduct.stockQuantity,
                })}
              />
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Thumbnail
              </Typography>
              <TextField
                {...register("thumbnail", {
                  required: "Thumbnail is required",
                  value: selectedProduct.thumbnail,
                })}
              />
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Thumbnail
              </Typography>
              {[...Array(3)].map((i, index) => (
                <TextField
                  key={index}
                  {...register(`thumbnails.[${index}]`, {
                    required: "Thumbnail is required",
                    value: selectedProduct?.thumbnails?.[index],
                  })}
                />
              ))}
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Product Images
              </Typography>
              {/* <Button
                variant="contained"
                onClick={() => setImages([...selectedProduct?.images, ""])}
              >
                Add Image
              </Button> */}

              <Stack rowGap={2}>
                {imgs?.map((image, index) => (
                  <TextField
                    {...register(`images[${index}]`, {
                      required: "Image is required",
                      value: image,
                    })}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack>
              {/* <Typography>Garment={garment}</Typography>
              <Select onChange={(e) => setGarment(e.target.value)}>
                <MenuItem value={"tshirt"}> tshirt</MenuItem>
                <MenuItem value={"hoodie"}>Hoodie</MenuItem>
                <MenuItem value={"sweatshirt"}>Sweatshirt</MenuItem>
              </Select> */}
              {/* {imgs?.map((i, index) => (
                <>
                  <TextField
                    label={index}
                    {...register(`imgs[${index}]`,{required:"Image is required"})}
                    onChange={(event) => {
                      // console.log(event?.target?.value)
                      imgs[index] = event?.target?.value;
                      console.log(imgs);
                      setImages(imgs);
                    }}
                  />
                </>
              ))} */}
              <h4>Hoodie pics</h4>
              {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.hoodie[${index}]`, {
                      required: "Image is required",
                      value: selectedProduct?.imgs?.hoodie?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
              <h4>Tshirt Images</h4>
               {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.tshirt[${index}]`, {
                      required: "Image is required",
                      value: selectedProduct?.imgs?.tshirt?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
              <h4>Sweatshirt Images</h4>
               {[...Array(4)]?.map((i, index) => (
               <TextField
                    label={index}
                    {...register(`imgs.sweatshirt[${index}]`, {
                      required: "Image is required",
                      value: selectedProduct?.imgs?.sweatshirt?.[index],
                    })}
                    
                    
                  />
                    
                    
              ))}
            </Stack>
          </Stack>

          {/* action area */}
          <Stack
            flexDirection={"row"}
            alignSelf={"flex-end"}
            columnGap={is480 ? 1 : 2}
          >
            <Button
              size={is480 ? "medium" : "large"}
              variant="contained"
              type="submit"
            >
              Update
            </Button>
            <Button
              size={is480 ? "medium" : "large"}
              variant="outlined"
              color="error"
              component={Link}
              to={"/admin/dashboard"}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
