import React, { useEffect } from 'react'
import { Navbar } from '../features/navigation/components/Navbar'
import { ProductList } from '../features/products/components/ProductList'
import { resetAddressStatus, selectAddressStatus } from '../features/address/AddressSlice'
import { useDispatch, useSelector } from 'react-redux'
import {Footer} from '../features/footer/Footer'
import Hover3d from '../features/Parallax/Hover3d'
import Parallax from '../features/Parallax/Parallax'
import { useMediaQuery,useTheme } from '@mui/material'

export const HomePage = () => {
      const theme=useTheme()
  
    const is488=useMediaQuery(theme.breakpoints.down(488))

  const dispatch=useDispatch()
  const addressStatus=useSelector(selectAddressStatus)

  useEffect(()=>{
    if(addressStatus==='fulfilled'){

      dispatch(resetAddressStatus())
    }
  },[addressStatus])

  return (
    <>
    <Navbar isProductList={true}/>
    {/* <Hover3d/> */}
    {is488?
  <Hover3d/>:  <Parallax/>
    
  }
    {/* <Parallax/> */}
    <ProductList/>
    <Footer/>
    </>
  )
}
